import { graphql, useStaticQuery } from 'gatsby'
import { DefaultTheme } from 'styled-components'
import { contentfulThemeToThemeExtension } from '../graphql/global/theme'
import { ContentfulTheme } from '../types/PgPages'
import { ContentfulImage } from '../graphql/contentfulTypes'

type QueryResult = {
  allContentfulTheme: {
    edges: {
      node: ContentfulTheme & { contentful_id: string; bodyBackgroundImage?: ContentfulImage }
    }[]
  }
}

const useTheme = (themeId?: string) => {
  const contentfulThemes = useStaticQuery<QueryResult>(graphql`
    query AllThemesQuery {
      allContentfulTheme {
        edges {
          node {
            contentful_id
            name
            bodyBackground
            bodyBackgroundAlt
            bodyText
            alternativeBodyTextColor
            brandDefault
            brandTextDefault
            iconsColor
            iconsHoverColor
            productViewWrapperBackground
            productViewContainerBackground
            layoutBackground
            buttonBackgroundPrimaryColor
            buttonPrimaryColor
            bodyBackgroundImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const getThemeById = (id: string) => {
    const theme = contentfulThemes.allContentfulTheme.edges.filter(({ node }) => node.contentful_id === id)[0]?.node
    if (!theme) return {}

    return { theme: contentfulThemeToThemeExtension(theme), bodyBackgroundImage: theme?.bodyBackgroundImage?.file?.url }
  }

  const currentTheme = getThemeById(themeId ?? '')
  const allThemes = contentfulThemes.allContentfulTheme.edges.map(({ node }) => contentfulThemeToThemeExtension(node))

  const mergeThemes = (current: DefaultTheme, extension = currentTheme?.theme) =>
    ({
      ...current,
      colors: { ...current.colors, ...extension },
    } as DefaultTheme)

  return {
    allThemes,
    currentTheme: currentTheme?.theme,
    bodyBackgroundImage: currentTheme?.bodyBackgroundImage,
    getThemeById,
    mergeThemes,
  }
}

export default useTheme
