import React from 'react'
import { connectStats } from 'react-instantsearch-dom'

interface Props {
  nbHits: string
  className: string
}
const Stats = ({ nbHits, className }: Props) => <span className={className}>{`${nbHits} resultados`}</span>

export default connectStats<any>(Stats)
