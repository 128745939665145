import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  ZIndex,
  Anchor,
  useResolution,
  AlgoliaProduct,
  DistributionCenter,
  slugify,
} from '@ecommerce/shared'
import { Configure } from 'react-instantsearch-dom'
import { navigate } from 'gatsby'
import { Icon } from '../Icon/Icon'
import Filter from '../Algolia/Filter'
import Loading from '../ShowLoading'
import IsFilter from '../Algolia/FilterSelected'
import Stats from '../Algolia/Stats'
import ResultProducts from '../Algolia/ResultProducts'
import { FiltersHomeSearch, InfoEmptySearch, sortListAlgolia } from '../../mocks'
import { useAppDispatch, useAppSelector } from '../../store'
import { resetFilterBySkus } from '../../store/algolia/filterSlice'
import {
  resetFilters,
  resetQuery,
  resetRefinementLists,
  selectConfigure,
  selectRefinementList,
  setRefinementList,
  selectIndexName,
} from '../../store/algolia/searchStateSlice'
import CustomSort from '../Algolia/CustomSort'
import { formatFilterBySkus } from '../../utils/algolia'
import useRefinementListQuery from '../../hooks/useRefinementListQuery'
import useSelectedRefinementList from '../../hooks/useSelectedRefinementList'

declare const window: Window

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const Wrapper = styled.div<{ isOpenFilter: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .SearchContainer__ {
    &left {
      width: ${(props) => (props.isOpenFilter ? '100%' : '0')};
      height: 100vh;
      top: 0;
      right: 0;
      position: fixed;
      transition: 100ms;
      z-index: ${ZIndex.medium};
      &-title,
      &-go_back {
        display: none;
      }
    }
    &right {
      width: 100%;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .SearchContainer__ {
      &left {
        width: ${(props) => (props.isOpenFilter ? '375px' : '0')};
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .SearchContainer__ {
      &left {
        width: 30%;
        height: auto;
        right: auto;
        position: relative;
        z-index: inherit;
        &-go_back {
          width: auto;
          height: 30px;
          display: flex;
          align-items: center;
          svg {
            width: 17px;
            height: 17px;
          }
          span {
            width: auto;
            padding-left: 3px;
            font-size: 14px;
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
          }
        }
      }
    }
    &right {
      width: 65%;
    }
  }
`
const Title = styled.div`
  width: 100%;
  height: 66px;
  padding: 0 20px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${ZIndex.low};
  .SearchContainer__ {
    &right {
      &-current {
        width: 155px;
        font-size: 15px;
        color: ${(props) => props.theme.colors.black};
      }
      &-stats {
        width: auto;
        font-size: 15px;
        color: ${(props) => props.theme.colors.black};
      }
      &-filter {
        width: 67px;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
          object-fit: contain;
          color: ${(props) => props.theme.colors.black};
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    height: 30px;
    padding: 0 0 0 20px;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
    .SearchContainer__ {
      &right {
        &-current {
          width: 236px;
          font-size: 24px;
          font-weight: bold;
          color: ${(props) => props.theme.colors.black};
        }
        &-filter {
          display: none;
        }
      }
    }
  }
`

const BlockedBox = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(3, 15, 28, 0.79);
  position: fixed;
  z-index: ${ZIndex.medium};
`

function blockedScreen(isBlocked: boolean) {
  const element = window.document.getElementById('layout')
  if (element) {
    if (isBlocked) {
      element.style.position = 'fixed'
    } else {
      element.style.position = 'absolute'
    }
  }
}

interface Props {
  currentDistributionCenter: DistributionCenter
  isEmptySearch: boolean
  excludeFilters?: { param: string; value: string }[]
  refinementListSelected?: string[]
  onHitClick?: (hit?: AlgoliaProduct) => void
  currentRefinement?: string
  defaultSearchValue?: string
}

const SearchContainer = (props: Props) => {
  const dispatch = useAppDispatch()

  const configure = useAppSelector(selectConfigure)
  const indexName = useAppSelector(selectIndexName)

  const selectedRefinementList = useAppSelector(selectRefinementList)

  const { currentDistributionCenter, isEmptySearch, refinementListSelected, onHitClick } = props

  const slugLocation = slugify(currentDistributionCenter.commerceLayer.stockLocation.name)

  const [isOpenFilter, setIsOpenFilter] = useState(false)

  const { isDesktop } = useResolution()

  const query = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '').get('query')
  const skusQuery = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '').get('skus')

  const filters: string[] = FiltersHomeSearch.map((filter) => filter.filter)

  function onOpenFilter() {
    setIsOpenFilter(true)
    blockedScreen(true)
  }

  function onCloseFilter() {
    setIsOpenFilter(false)
    blockedScreen(false)
  }

  useRefinementListQuery(filters, setRefinementList, query)
  useSelectedRefinementList(selectedRefinementList, query, skusQuery ?? undefined)

  useEffect(() => {
    return () => {
      dispatch(resetFilterBySkus())
      dispatch(resetRefinementLists())
      dispatch(resetFilters())
      dispatch(resetQuery())
    }
  }, [dispatch])

  return (
    <Container>
      <Loading />
      {isOpenFilter && <BlockedBox />}
      <Wrapper isOpenFilter={isOpenFilter}>
        <div className="SearchContainer__left">
          <Anchor className="SearchContainer__left-go_back" onClick={() => navigate(`/`)}>
            <Icon iconId="arrow_left" />
            <span>Volver a inicio</span>
          </Anchor>
          <Filter
            onCloseFilter={onCloseFilter}
            filters={FiltersHomeSearch}
            refinementListSelected={refinementListSelected}
          />
        </div>

        <div className="SearchContainer__right">
          <Title className="SearchContainer__right-title">
            {isEmptySearch ? (
              <span className="SearchContainer__right-current">Los más buscados</span>
            ) : (
              <Stats className="SearchContainer__right-stats" />
            )}
            {isDesktop && <CustomSort items={sortListAlgolia} defaultRefinement={indexName} />}
            <Anchor className="SearchContainer__right-filter" onClick={onOpenFilter}>
              <IsFilter />
              <span>Filtros</span>
            </Anchor>
          </Title>
          <Configure
            filters={`${configure.filters} AND slugLocation:${slugLocation} ${formatFilterBySkus(skusQuery)}`}
            hitsPerPage={configure.hitsPerPage}
            query={query || ''}
          />
          <ResultProducts
            urlImage={InfoEmptySearch.urlImage}
            text={InfoEmptySearch.text}
            onHitClick={onHitClick}
            listName="Search"
          />
        </div>
      </Wrapper>
    </Container>
  )
}

export default SearchContainer
