import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { breakpoints, getStoredDistributionCenter, useResolution } from '@ecommerce/shared'
import Layout from '../components/Layout'
import { useCatalogHitClick } from '../hooks/useCatalogHitClick'
import { NavBarPage } from '../components/NavBar/NavBarPage'
import withPageTransition from '../components/withPageTransition'
import { PgPageProps } from '../types/PgPages'
import useTheme from '../hooks/useTheme'
import CustomSearch from '../components/Algolia/CustomSearch'
import SearchContainer from '../components/SearchContainer'

const WrapperContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 231px);
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
  }
  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
    padding: 77px 20px 0 20px;
  }
  @media (${breakpoints.desktop.medium}) {
    padding: 77px 120px 0 140px;
  }
`
interface FilterList {
  key: string
  value: string
}

const SearchHome = (props: PgPageProps) => {
  const { productCategories } = props.pageContext

  const { location } = props

  const currentDistributionCenter = getStoredDistributionCenter() || props.pageContext.currentDistributionCenter
  const { isDesktop } = useResolution()

  const refinementList = location?.state?.refinementList

  const [isEmptySearch, setIsEmptySearch] = useState(false)
  const [filters, setFilters] = useState<FilterList[]>([])
  const [defaultSearchValue, setDefaultSearchValue] = useState('')

  useEffect(() => {
    const newFilter: FilterList[] = []
    const params = new URLSearchParams(location?.search)
    params.forEach((value: string, key: string) => {
      newFilter.push({ key: `${key}`, value: `${value}` })
    })
    setFilters(newFilter)
    const searchFilter = newFilter.find((filter) => filter.key === 'query')
    if (searchFilter) setDefaultSearchValue(searchFilter.value)
  }, [location, location?.search])

  const themeId = location?.state?.themeId
  const { mergeThemes } = useTheme(themeId)

  const onCatalogHitClick = useCatalogHitClick({ listName: 'búsqueda de productos', themeId })

  const filterReturnability = Object.keys(filters).map((item: any) => filters[item].key)

  const refinementListSelected = (
    (refinementList && refinementList && Object.keys(refinementList).filter((item) => refinementList[item])) ||
    []
  ).concat(filterReturnability)

  function isEmptyInput(value: boolean) {
    setIsEmptySearch(value)
  }

  const navbar = () => (
    <NavBarPage centerComponent={<CustomSearch isEmptyInput={isEmptyInput} />} categories={productCategories ?? []} />
  )

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout
        id="layout"
        title="Buscar Productos"
        pathName="search"
        navbar={isDesktop ? undefined : navbar}
        useAlgoliaSearch
        defaultSearchValue={defaultSearchValue}
        categories={productCategories}
        currentDistributionCenter={currentDistributionCenter}
      >
        <WrapperContainer>
          <SearchContainer
            currentDistributionCenter={currentDistributionCenter}
            isEmptySearch={isEmptySearch}
            refinementListSelected={refinementListSelected}
            onHitClick={onCatalogHitClick}
            defaultSearchValue={defaultSearchValue}
          />
        </WrapperContainer>
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(SearchHome)
