import React, { useEffect, useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { connectSearchBox } from 'react-instantsearch-dom'
import { SearchInput, breakpoints, ZIndex, debounce, getStoredMarket } from '@ecommerce/shared'
import qs from 'qs'
import { Icon } from '../Icon/Icon'

interface SearchBarContainerProps {
  hasText?: boolean
}

const SearchBarContainer = styled.div<SearchBarContainerProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey};
  height: 0;
  .search-bar {
    width: 68%;
    height: 40px;
    position: absolute;
    transform: translate(0, -20.5px);
    z-index: ${ZIndex.low};

    &-input {
      transition: padding-left 0.15s;
      ${({ hasText }) => (hasText ? `padding-left: 11px;` : '')}
    }
    svg {
      margin-left: 6px;
    }
  }
  @media (${breakpoints.phoneSmallLandscape.min}) {
    .search-bar {
      min-width: 34%;
      max-width: 420px;
      svg {
        margin-left: 13px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    background-color: ${(props) => props.theme.colors.grey};
    height: 0;

    .search-bar {
      transform: translate(0, 20px);
    }
  }
`
interface Props {
  refine?: (event: string) => void
  isEmptyInput: (value: boolean) => void
  placeHolder?: string
  activateOnEnter?: boolean
}

interface QueryParam {
  query: string
}

const SearchBox = ({ refine, isEmptyInput, placeHolder, activateOnEnter }: Props) => {
  const { query: queryString } = (qs.parse(
    typeof window !== 'undefined' ? window.location.search.slice(1) : '',
  ) as unknown) as QueryParam

  const [text, setText] = useState(queryString)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const debouncedRefine = useRef(refine && !activateOnEnter ? debounce(refine, 300) : () => null)

  const onRefine = useCallback((search: string) => {
    setText(search)
    debouncedRefine.current(search)
  }, [])

  return (
    <form noValidate role="search">
      <SearchBarContainer hasText={!!text}>
        <SearchInput
          className="search-bar"
          type="text"
          placeholder={`Busca ${placeHolder?.toLocaleLowerCase() || 'algún producto'}`}
          CustomSearchIcon={() => (!text ? <Icon iconId="search" /> : <div />)}
          CustomCloseIcon={() => <Icon iconId="close" />}
          hasText={!!text}
          reset={() => onRefine('')}
          autoFocus
          value={text}
          onChange={(event) => onRefine(event.currentTarget.value)}
          reference={inputRef}
          keyDownHandler={(e) => {
            e.stopPropagation()
            if (e.key === 'Enter') {
              e.preventDefault()
              if (inputRef.current) inputRef.current.blur()
              const currentCity = getStoredMarket()
              if (!currentCity) return
              const query = e.currentTarget.value ?? (e.target as HTMLInputElement).value
              navigate(`/search?query=${encodeURI(query)}`, { state: { query } })
            }
          }}
          inputClassName="search-bar-input"
        />
      </SearchBarContainer>
    </form>
  )
}

export default SearchBox
